
import { defineComponent, onMounted, ref, watch } from "vue";
import axios, { AxiosError } from 'axios';
import { useAuthStore } from "@/store/AuthStore";
import useAlert from "@/composables/Alert";
import { Modal } from "bootstrap";
import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";
import { CampTiptapEditor } from '@/components';
import { useEditor } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'

interface IPostNotification {
  message: string,
  type: string,
  url_media: string,
  priority: string,
  send_date: string,
  send_to: string
}

type TYPE_SENDER_OPTIONS = {
  COMPANY: {
    KEY: number;
    LABEL: string;
  };
  STORE: {
    KEY: number;
    LABEL: string;
  };
};

type TSender = { key: number | string, label: string }

function truncateFileName(fileName: string, limit = 10): string {
  if (fileName.length <= limit) {
    return fileName;
  } else {
    const extension = fileName.substring(fileName.lastIndexOf('.'));
    const truncatedName = fileName.substring(0, limit - extension.length) + '... ' + extension;
    return truncatedName;
  }
}

export default defineComponent({
  name: "SenderNotificationModal",
  emits: [ "getPosts", "closeModal", "closeModalTeste" ],
  props:{
    relationData: Object,
    isChangeProps: Boolean,
    closeModal: HTMLElement,
    closeModalTeste: Function,
    count: Number,
    postSelectedEdit: Object
  },
  components: {
    ElConfigProvider,
    CampTiptapEditor
  },
  setup(props, {emit}) {
    const userStore = useAuthStore()
    const enableAllCompanies = ref(false)
    const enableConfigSection = ref(false)
    const editor = useEditor({
      content: '',
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder: 'Escreva a mensagem...',
          emptyNodeClass: 'my-custom-is-empty-class',
        }),
        Underline,
        Link.configure({
          openOnClick: true,
        }),
      ],
    })
    const userLevel = ref()
    const switNumberStep = ref(1)
    const modalCompanyShow = ref(false)
    const companies = ref()
    const loading = ref() 
    const entityFilter = ref<any>([])
    const isAlltrue = ref(false)
    const filterDom = ref('')
    const sendTo = ref('')
    const config = ref({
      type: "",
      priority: "",
      date: "",
      time: ""
    })
    const send = ref({
      notificationTitle: "",
      notificationMessage: "",
      message: "",
    })
    const sendAll = ref(false)
    const { showTimeAlert } = useAlert()

    const SENDER_OPTIONS: TYPE_SENDER_OPTIONS = {
      COMPANY: {
        KEY: 1,
        LABEL: "Companhia"
      },
      STORE: {
        KEY: 2,
        LABEL: "Loja"
      }
    }
    const senderId = ref(0)
    const senderList = ref<TSender[] | null>(
      userStore.isAdmUser ?
      [
        { key: 0, label: userStore.getUser.name },
        { key: SENDER_OPTIONS.COMPANY.KEY, label: SENDER_OPTIONS.COMPANY.LABEL },
        { key: SENDER_OPTIONS.STORE.KEY, label: SENDER_OPTIONS.STORE.LABEL }
      ]
      :
      [
        { key: 0, label: userStore.getUser.name },
        { key: SENDER_OPTIONS.STORE.KEY, label: SENDER_OPTIONS.STORE.LABEL }
      ]
    )
    const senderCompanyId = ref<number | string>("")
    const senderCompanyList = ref<TSender[] | null>(null)
    const senderStoreId = ref<number | string>("")
    const senderStoreList = ref<TSender[] | null>(null)
    const senderForwardBtnDisabled = ref(true)
    const senderDesc = ref("")

    const mediaFile = ref<File | null>(null)
    const mediaFileURL = ref("")
    const contentType = ref("")
    const isImageFile = ref(false)
    const isVideoFile = ref(false)

    const idJob = ref<number | null>(null)
    const jobName = ref()
    const idPost = ref(0)
    const feedbackErrorList = ref<string[] | []>([])

    function disabledDateInNotification(time: Date) {
      return time.getTime() < Date.now() - 86400000;
    }

    async function resetValues() {
      entityFilter.value = []
      isAlltrue.value = false
      send.value.notificationMessage = ""
      send.value.notificationTitle = ""
      send.value.message = ""
      // send.value.media = ""
      // send.value.mediaName = "Selecione uma mídia:"
      config.value.type = ""
      config.value.date = ""
      config.value.time = ""
      config.value.priority = ""
      sendTo.value = ""
      switNumberStep.value = 1
      modalCompanyShow.value = false
      senderId.value = 0
      senderCompanyId.value = ""
      senderStoreId.value = ""
      sendAll.value = false
      idJob.value = null
      idPost.value = 0
      mediaFile.value = null
      mediaFileURL.value = ''
      editor.value?.commands.clearContent(true)
      feedbackErrorList.value = []
      return
    }

    async function resetEditorValues() {
      const $element = document.querySelector(".camp-tiptap-editor-body > div")
      if(!$element) return
      while($element.firstChild){
        $element.removeChild($element.firstChild);
      }
      return
    }

    function selectAll() {
      resetValues()
      modalCompanyShow.value = true
      switNumberStep.value = 2
      sendAll.value = true
    }

    async function selectCompany() {
      loading.value = true
      resetValues()
      try {
        const response = await axios.get('/api/getCompanyList')
        companies.value = response.data.data.map(elem => {
          return {company: elem, checked: false}
        })

        sendTo.value = "Companhia"
      } catch (error) {

      } finally {
        loading.value = false
      }
      modalCompanyShow.value = true
    }

    async function selectStore() {
      resetValues()
      loading.value = true
      try {
        const response = await axios.get('/api/getStoreList')
        companies.value = response.data.data.map(elem => {
          return {company: elem, checked: false}
        })
        sendTo.value = 'Loja'
      } catch (error) {
        
      } finally {
        loading.value = false
      }
      modalCompanyShow.value = true
    }

    async function selectCompetitor() {
      resetValues()
      loading.value = true
      try {
        const response = await axios.get('/api/getCompetitorList')
        companies.value = response.data.data.map(elem => {
          return {company: elem, checked: false}
        })
        sendTo.value = 'Vendedor'
      } catch (error) {
        
      } finally {
        loading.value = false
      }
      modalCompanyShow.value = true
    }

    function setEntity(entity) {
        entity.checked = !entity.checked
    }

    function backSwitch() {
      /** Before */
      // modalCompanyShow.value = false
      // switNumberStep.value > 1 ? switNumberStep.value = switNumberStep.value - 1: switNumberStep.value

      /** After */
      modalCompanyShow.value = false
      if(switNumberStep.value > 1)
        switNumberStep.value = 1
    }

    async function handleSenderList(senderId: number) {
      try {
        if(senderId === SENDER_OPTIONS.COMPANY.KEY && senderCompanyList.value === null) {
          const { data } = (await axios.get(`/api/getCompanyList`)).data
          senderCompanyList.value = [ { key: "", label: "Selecione uma Companhia" }, ...data.map(el => ({ key: el.id, label: el.fantasy_name }))]
        }
        if(senderId === SENDER_OPTIONS.STORE.KEY && senderStoreList.value === null) {
          const { data } = (await axios.get(`/api/getStoreList`)).data
          senderStoreList.value = [ { key: "", label: "Selecione uma Loja" }, ...data.map(el => ({ key: el.id, label: el.fantasy_name }))]
        }
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      }
    }

    function hanldeSenderForwardBtn() {
      senderForwardBtnDisabled.value = true
      if(senderId.value === 0) {
        senderForwardBtnDisabled.value = false
        senderDesc.value = userStore.getUser.name
      }
      if(SENDER_OPTIONS.COMPANY.KEY === senderId.value && senderCompanyId.value) {
        senderForwardBtnDisabled.value = false
        if(Array.isArray(senderCompanyList.value)) {
          senderDesc.value = `${(senderCompanyList.value.find(el => el.key === senderCompanyId.value))?.label}`
        }
      }
      if(SENDER_OPTIONS.STORE.KEY === senderId.value && senderStoreId.value) {
        senderForwardBtnDisabled.value = false
        if(Array.isArray(senderStoreList.value)) {
          senderDesc.value = `${(senderStoreList.value.find(el => el.key === senderStoreId.value))?.label}`
        }
      }
    }

    function focusSelectSendDate() {
      const $input: HTMLInputElement | null = document.querySelector('#select-send-date-cb32f05b-8852-4378-abdd-a382a59d7ed2 input')
      $input!.focus()
    }

    async function sendNotification() {
      let success = false
      try {
        loading.value = true

        const bodyToPost = {
          id_user: userStore.getUser.id,
          recipient_id_list: entityFilter.value.map((elem: any) => elem.company.id),
          recipient_type: sendAll.value? 'todos' : sendTo.value.toLowerCase(),
          title: send.value.notificationTitle,
          message: send.value.notificationMessage,
        }

        await axios.post('/api/pushNotificationCustom', { ...bodyToPost })

        showTimeAlert('Notificação publicada com sucesso')
        emit('closeModalTeste')
        emit('getPosts')
        success = true
      } catch (error) {
        success = false
        if(error instanceof AxiosError) {
          if(error.response?.data.errorCode == "print")
            feedbackErrorList.value = error.response?.data.message
          showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado", "error")
        }
      } finally {
        loading.value = false
        if(success) {
          idJob.value = null
          resetValues();
          resetEditorValues();
        }
      }
    }

    const auxModal:any = ref(null);
    const openModal = id => { 
      const auxElement = document.querySelector(`#${id}`);
      auxModal.value = new Modal(auxElement);
      auxModal.value.show();
    };

    watch(() => isAlltrue.value, () => {
      if(isAlltrue.value){
        companies.value.forEach(elem => {
          elem.checked = true
        })
      } else {
        companies.value.forEach(elem => {
          elem.checked = false
        })
      }
    })

    watch(() => companies.value, () => {
      companies.value.forEach((element, index) => {
        if(element.checked) {
          entityFilter.value = entityFilter.value.filter(elem => elem.company.id != element.company.id)
          entityFilter.value.push(element)
        } else {
          entityFilter.value = entityFilter.value.filter(elem => elem.company.id != element.company.id)
        }
      });
    },{ deep: true})

    watch(() => props.isChangeProps, () => userLevel.value = userStore.getUser.role.level, { deep: true})

    watch(() => { senderId.value }, () => handleSenderList(senderId.value), { deep: true })

    onMounted(() => hanldeSenderForwardBtn())

    watch(() => { senderId.value, senderCompanyId.value, senderStoreId.value }, () => hanldeSenderForwardBtn(), { deep: true })

    watch(() => editor.value?.getHTML(), () => send.value.message = editor.value?.getHTML() || "")

    return {
      enableAllCompanies,
      enableConfigSection,
      modalCompanyShow,
      selectCompany,
      companies,
      loading,
      switNumberStep,
      userLevel,
      setEntity,
      entityFilter,
      isAlltrue,
      backSwitch,
      filterDom,
      selectAll,
      config,
      send,
      sendNotification,
      selectStore,
      selectCompetitor,
      sendTo,
      auxModal,
      openModal,
      ptBr,
      disabledDateInNotification,
      senderId,
      senderList,
      senderCompanyId,
      senderCompanyList,
      senderStoreId,
      senderStoreList,
      senderForwardBtnDisabled,
      senderDesc,
      focusSelectSendDate,
      isImageFile,
      isVideoFile,
      contentType,
      truncateFileName,
      userStore,
      editor,
      jobName,
      idJob,
      idPost,
      resetValues,
      resetEditorValues,
      feedbackErrorList,
    }
  }
})
